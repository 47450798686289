<template>
  <v-card tile min-width="200">
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-col class="pa-3">
          <div class="layout justify-center align-center">
            <v-img :src="require(`@/assets/${image}`)"  width="10" height="50" contain/>
          </div>
        </v-col>
        <v-col class="pa-3"  :style="color">
          <div class="white--text text-h5">{{ title }}</div>
          <span class="white--text">{{ subTitle }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    subTitle: String,
    color: String,
  },
}
</script>