<template>
  <v-app>
    <Navbar v-if="!$route.meta.requiresGuest" />
    <v-main>
      <router-view class="pa-0 ma-0" />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
  methods: {
    
  },
  watch: {
      '$route':{
        handler: (to, ) => {
          document.title = to.meta.title || 'Edutube-Console'
        },
         immediate: true
      }
    },
};
</script>
