<template>
  <v-container>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title> Select Medium, Grade and Subject to view list </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3">
                  <v-autocomplete
                    v-model="mediumID"
                    :loading="loading"
                    :items="mediums"
                    :rules="[rules.required]"
                    item-text="value"
                    item-value="id"
                    label="Medium"
                    placeholder="Select..."
                    cache-items
                    required
                    @change="getLessons"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3">
                  <v-autocomplete
                    v-model="gradeID"
                    :loading="loading"
                    :items="grades"
                    :rules="[rules.required]"
                    item-text="value"
                    item-value="id"
                    label="Grade"
                    placeholder="Select..."
                    cache-items
                    required
                    @change="getLessons"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="3">
                  <v-autocomplete
                    v-model="subjectID"
                    :loading="loading"
                    :items="subjects"
                    :rules="[rules.required]"
                    item-text="value"
                    item-value="id"
                    label="Subject"
                    placeholder="Select..."
                    cache-items
                    required
                    @change="getLessons"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4" lg="5" xl="6">
                  <v-btn
                    elevation="0"
                    color="primary"
                    block
                    class="mb-5"
                    @click="validate"
                    :disabled="!valid"
                  >
                    Add Lesson
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center"
      ><v-col cols="12" xs="12" sm="12" md="4" lg="5" xl="6">
        <v-progress-linear
          v-if="tableLoading"
          indeterminate
          color="accent"
        ></v-progress-linear>
        <v-list v-if="!tableLoading" dense>
          <v-list-item v-for="(item, index) in lessons" :key="index">
            <v-list-item-content>
              <v-list-item-title
                ><span>{{ item }}</span></v-list-item-title
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="deleteFromList(index)">
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list></v-col
      ></v-row
    >
    <!-- Dialog Add lesson -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline"> Add Lesson </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="lesson"
            label="Lesson Name"
            counter="100"
            :rules="[rules.required, rules.max]"
            hint="Enter lesson name"
            :error-messages="errorMessages"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="nav" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="accent" text @click="addLesson"> Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";

export default {
  data: () => ({
    valid: false,
    loading: true,
    working: false,
    mediums: [],
    grades: [],
    subjects: [],
    mediumID: "",
    gradeID: "",
    subjectID: "",
    lessons: [],
    lesson: "",
    errorMessages: "",
    tableLoading: false,
    dialog: false,
    rules: {
      required: (value) => !!value || "Feild cannot be empty",
      max: (v) => v.length <= 100 || "Max 100 characters",
    },
    headers: [
      {
        text: "Lesson Name",
        align: "start",
        value: "name",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getMediums();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.lesson = "";
        this.dialog = true;
      } else {
        this.$refs.form.validate();
      }
    },
    getMediums() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("mediums")
        .orderBy("medium")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().medium,
            };
            this.mediums.push(data);
          });
          this.getGrades();
        });
    },
    getGrades() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("grades")
        .orderBy("grade")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().grade,
            };
            this.grades.push(data);
          });
          this.getSubjects();
        });
    },
    getSubjects() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("subjects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().subject,
            };
            this.subjects.push(data);
          });
          this.loading = false;
          this.emitLoadingState();
        });
    },
    getLessons() {
      if (this.mediumID != "" && this.gradeID != "" && this.subjectID != "") {
        this.lessons = [];
        this.tableLoading = true;
        firebaseFirestore
          .collection("lessons-list")
          .doc(this.gradeID)
          .collection(this.subjectID)
          .doc(this.mediumID)
          .get()
          .then((snapshot) => {
            if (!snapshot.exists) {
              this.tableLoading = false;
            } else {
              this.lessons = snapshot.data().lessons;
              this.tableLoading = false;
            }
          });
      }
    },
    deleteFromList(index) {
      this.tableLoading = true;
      this.lessons.splice(index, 1);
      this.updateLessons();
    },
    addLesson() {
      this.tableLoading = true;
      this.dialog = false;
      this.lessons.push(this.lesson);
      this.updateLessons();
    },
    updateLessons() {
      if (this.mediumID != "" && this.gradeID != "" && this.subjectID != "") {
        firebaseFirestore
          .collection("lessons-list")
          .doc(this.gradeID)
          .collection(this.subjectID)
          .doc(this.mediumID)
          .set({
            lessons: this.lessons,
          })
          .then(() => {
            this.getLessons();
          });
      }
    },
  },
};
</script>

<style></style>
