import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app';
require("firebase/compat/auth");

import Login from '../views/login/login'
import Dashboard from '../views/home/dashboard'
import AppUsers from '../views/userManagment/appUsers'
import ConsoleUsers from '../views/userManagment/consoleUsers'
import CreateUser from '../views/userManagment/createUser'
import EditUser from '../views/userManagment/editUser'
import UploadVideo from '../views/videoManagement/uploadVideo'
import Channels from '../views/videoManagement/channels'
import ViewChannel from '../views/videoManagement/viewChannel'
import ViewVideo from '../views/videoManagement/viewVideo'
import Controls from '../views/controls/controls'
import LessonsList from '../views/controls/lessonsList'
import SendNotification from '../views/notifications/sendNotification'
import reportedVideos from '../views/reportsRequests/reportedVideos'
import viewReport from '../views/reportsRequests/viewReport'

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    }
  },
  {
    path: "/appUsers",
    name: "AppUsers",
    component: AppUsers,
    meta: {
      requiresAuth: true,
      title: "App Users",
      breadcrumb: [
        { name: 'App Users'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/consoleUsers",
    name: "ConsoleUsers",
    component: ConsoleUsers,
    meta: {
      requiresAuth: true,
      title: "Console Users",
      breadcrumb: [
        { name: 'Console Users'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/consoleUsers/createUser",
    name: "CreateUSer",
    component: CreateUser,
    meta: {
      requiresAuth: true,
      title: "Create User",
      breadcrumb: [
        { name: 'Console Users', href: '/consoleUsers'},
        { name: 'Create User'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/consoleUsers/:userID",
    name: "Edit User",
    component: EditUser,
    meta: {
      requiresAuth: true,
      title: "Edit User",
      breadcrumb: [
        { name: 'Console Users', href: '/consoleUsers'},
        { name: 'Edit User'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/uploadVideo",
    name: "Upload Video",
    component: UploadVideo,
    meta: {
      requiresAuth: true,
      title: "Upload Video",
      breadcrumb: [
        { name: 'Upload Video'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/channels",
    name: "Channels",
    component: Channels,
    meta: {
      requiresAuth: true,
      title: "Channels",
      breadcrumb: [
        { name: 'Channels'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/channels/:channelID/:channelName",
    name: "Channel",
    component: ViewChannel,
    meta: {
      requiresAuth: true,
      title: "Channel",
      breadcrumb: [
        { name: 'Channels', href: '/channels'},
        { name: 'Channel'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/video/:channelID/:videoID/:videoTitle",
    name: "Video",
    component: ViewVideo,
    meta: {
      requiresAuth: true,
      title: "Video",
      breadcrumb: [
        { name: 'Video'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/controls",
    name: "Controls",
    component: Controls,
    meta: {
      requiresAuth: true,
      title: "Controls",
      breadcrumb: [
        { name: 'Controls'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/controls/Lessonslist",
    name: "Lessons List",
    component: LessonsList,
    meta: {
      requiresAuth: true,
      title: "Lessons List",
      breadcrumb: [
        { name: 'Lessons List'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/sendNotification",
    name: "SendNotification",
    component: SendNotification,
    meta: {
      requiresAuth: true,
      title: "Send Notification",
      breadcrumb: [
        { name: 'Send Notification'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/reportedVideos",
    name: "ReportedVideos",
    component: reportedVideos,
    meta: {
      requiresAuth: true,
      title: "Reported Videos",
      breadcrumb: [
        { name: 'Reported Videos'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
  {
    path: "/reportedVideos/:channelID/:videoID",
    name: "ViewReport",
    component: viewReport,
    meta: {
      requiresAuth: true,
      title: "View Report",
      breadcrumb: [
        { name: 'View Report'},
      ], 
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.userRole == "console-user") {
        next('/accessdenied/403');
      } else {
        next();
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Nav Guard
router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});


export default router;
