<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      bottom
      :permanent="navStatus"
      color="nav"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ userName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link href="/">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/channels">
          <v-list-item-icon>
            <v-icon>mdi-movie</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Channels</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/uploadVideo">
          <v-list-item-icon>
            <v-icon>mdi-cloud-upload</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Upload Video</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item href="/controls">
          <v-list-item-icon>
            <v-icon>mdi-archive-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Controls</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/consoleUsers">
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Console Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/appUsers">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>App Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item href="/sendNotification">
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Send Notification</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/reportedVideos">
          <v-list-item-icon>
            <v-icon>mdi-flag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reported Videos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout" large color="accent"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      shrink-on-scroll
      prominent
      fade-img-on-scroll
      color="nav"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn icon large @click="changeTheme">
        <v-icon>mdi-brightness-6</v-icon>
      </v-btn>
      <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>
      <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        bottom
        height="10"
        color="accent"
      ></v-progress-linear>
    </v-app-bar>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseAuth, firebaseFirestore } from "@/main";
import { EventBus } from "@/main";

require("firebase/auth");

export default {
  data() {
    return {
      title: "",
      drawer: false,
      userName: "",
      userEmail: "",
      userRole: "",
      loading: false,
      items: [],
    };
  },

  watch: {
    $route() {
      const self = this;
      self.updateTitle();
      self.updateBreadcrumbs();
    },
  },

  mounted() {
    const self = this;
    self.updateTitle();
    self.updateBreadcrumbs();
  },

  created() {
    this.userEmail = localStorage.userEmail;
    this.userName = localStorage.userName;
    this.userRole = parseInt(localStorage.userRole);

    const self = this;
    EventBus.$on("loadingState", (payload) => {
      //console.log("Loading - " + payload);
      self.loading = payload;
    });

    if (localStorage.theme == "dark") {
      this.$vuetify.theme.dark = true;
      this.darkMode = true;
    } else if (localStorage.theme == "light") {
      this.$vuetify.theme.dark = false;
      this.darkMode = false;
    }
  },

  computed: {
    navStatus() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
      return null;
    },
  },

  methods: {
    changeTheme() {
      if (this.$vuetify.theme.dark == false) {
        this.$vuetify.theme.dark = true;
        this.darkMode = true;
        localStorage.theme = "dark";
      } else {
        this.$vuetify.theme.dark = false;
        this.darkMode = false;
        localStorage.theme = "light";
      }
    },
    updateTitle() {
      const self = this;
      self.title = self.$route.meta.title;
    },
    updateBreadcrumbs() {
      const self = this;
      self.items = self.$route.meta.breadcrumb;
    },
    logout: function () {
      firebaseAuth.signOut().then(() => {
        localStorage.clear();
        this.$router.go({ path: this.$router.path });
      });
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  text-decoration: none;
  font: 24px Sans-Serif;
  margin-top: 5%;
}
</style>