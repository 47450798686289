<template>
  <v-container fluid>
    <v-stepper v-model="currentStep" v-if="!loading" class="ma-2">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1" color="accent">
          Select channel to publish
          <small>Channel of the teacher</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="currentStep > 2" step="2" color="accent">
          Title
          <small>Video title</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3" color="accent">
          Description
          <small>Video Description</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4" color="accent">
          Category
          <small>Grade, Medium and Subject</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 5" step="5" color="accent">
          Meta tags
          <small>keywords used for searching</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 6" step="6" color="accent">
          Thumbnail
          <small>Upload thumbnail</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="7" color="accent" editable>
          Video<small>Upload video</small>
        </v-stepper-step>
      </v-stepper-header>

      <!-- ----------------------------------------------------------------------------- -->
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" height="300px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="7" lg="6" xl="5">
                  <v-autocomplete
                    v-model="channel"
                    :items="channels"
                    item-text="name"
                    item-value="id"
                    filled
                    rounded
                    required
                    color="secondary"
                    @change="checkFeilds"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn
            color="accent"
            @click="currentStep = currentStep + 1"
            :disabled="stepValid[currentStep]"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" height="300px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="9" lg="8" xl="7">
                  <v-text-field
                    class="fieldsStyle"
                    v-model="title"
                    :rules="[rules.required, rules.min2, rules.max100]"
                    counter="100"
                    hint="Title"
                    filled
                    rounded
                    
                    :error-messages="titleErrorText"
                    @input="checkFeilds"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn
            color="accent"
            @click="currentStep = currentStep + 1"
            :disabled="stepValid[currentStep]"
          >
            Continue
          </v-btn>

          <v-btn text @click="currentStep = currentStep - 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" height="100%" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="8" lg="7" xl="6">
                  <v-textarea
                    v-model="description"
                    counter
                    label="Description"
                    :rules="[rules.max1000]"
                    :value="description"
                    rows="12"
                    filled
                    rounded
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn color="accent" @click="currentStep = currentStep + 1"> Continue </v-btn>

          <v-btn text @click="currentStep = currentStep - 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12" height="300px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
                  <v-overflow-btn
                    v-model="medium"
                    :items="mediums"
                    label="Medium"
                    item-value="id"
                    item-text="mediumString"
                    @change="checkFeilds"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
                  <v-overflow-btn
                    v-model="grade"
                    :items="grades"
                    label="Grade"
                    item-value="id"
                    item-text="gradeString"
                    @change="checkFeilds"
                  ></v-overflow-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="11" md="4" lg="4" xl="3">
                  <v-overflow-btn
                    v-model="subject"
                    :items="subjects"
                    label="Subject"
                    item-value="id"
                    item-text="subjectString"
                    @change="checkFeilds"
                  ></v-overflow-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn
            color="accent"
            @click="currentStep = currentStep + 1"
            :disabled="stepValid[currentStep]"
          >
            Continue
          </v-btn>

          <v-btn text @click="currentStep = currentStep - 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="mb-12" height="400px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="10" lg="9" xl="8">
                  <v-combobox
                    v-model="meta"
                    :items="items"
                    label="Type keywords and press enter"
                    chips
                    clearable
                    multiple
                    solo
                    rounded
                    filled
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn color="accent" @click="currentStep = currentStep + 1"> Continue </v-btn>

          <v-btn text @click="currentStep = currentStep - 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-card class="mb-12" height="400px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="6" lg="5" xl="4">
                  <v-img
                    v-if="thumbFile != null"
                    max-height="480"
                    max-width="852"
                    :src="thumbSRC"
                  ></v-img>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1"> </v-col>
                <v-col cols="12" xs="12" sm="11" md="6" lg="5" xl="4">
                  <v-file-input
                    v-model="thumbFile"
                    label="Pick a thumbnail"
                    hint="852 x 480 pixels & below 100 kb"
                    :persistent-hint="true"
                    accept=".jpg"
                    prepend-icon="mdi-image-area"
                    :error-messages="thumbErrorTxt"
                    :error="thumbError"
                    @change="checkFeilds"
                    :rules="[rules.fileRequired, rules.thumbSize]"
                    color="secondary"
                    :show-size="1000"
                    filled
                    rounded
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn
            color="accent"
            @click="currentStep = currentStep + 1"
            :disabled="stepValid[currentStep]"
          >
            Continue
          </v-btn>

          <v-btn text @click="currentStep = currentStep - 1"> Back </v-btn>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-card class="mb-12" height="400px" color="fill">
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col cols="12" xs="12" sm="11" md="6" lg="6" xl="5">
                  <div class="player-container">
                    <vue-core-video-player
                      v-if="videoFile != null && !submitBtn"
                      :src="videoSRC"
                    ></vue-core-video-player>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="1" lg="1" xl="1"> </v-col>
                <v-col cols="12" xs="12" sm="11" md="6" lg="5" xl="4">
                  <v-file-input
                    v-model="videoFile"
                    label="Upload Video"
                    hint="852 x 480 pixels & below 200 MB"
                    :persistent-hint="true"
                    accept=".mp4"
                    prepend-icon="mdi-movie"
                    :error-messages="videoErrorTxt"
                    :error="videoError"
                    @change="checkFeilds"
                    :rules="[rules.fileRequired, rules.videoSize]"
                    color="secondary"
                    :disabled="working"
                    :show-size="1000"
                    filled
                    rounded
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-btn
            v-if="!working && !success"
            color="accent"
            :disabled="submitBtn"
            @click="writeToDB"
          >
            Submit & Upload
          </v-btn>

          <v-btn text v-if="!working && !success" @click="currentStep = currentStep - 1">
            Back
          </v-btn>
          <v-progress-linear
            class="progressPad"
            v-if="working"
            v-model="progress"
            :indeterminate="progressIntermidiate"
            height="25"
            stream
            rounded
            striped
            color="accent"
            ><strong
              >{{ Math.ceil(progress) }}% {{ progressText }}</strong
            ></v-progress-linear
          >
          <v-alert v-if="success" text type="success">
            <strong>Video created successfully!</strong>
          </v-alert>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage } from "@/main";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      loading: true,
      working: false,
      valid: true,
      currentStep: 1,
      stepValid: [null, true, true, true, true, true, true],
      channels: [],
      channel: "",
      title: "",
      tiitleError: false,
      titleErrorText: "",
      description: "",
      mediums: [],
      grades: [],
      subjects: [],
      medium: "",
      grade: "",
      subject: "",
      meta: [],
      items: [],
      thumbFile: null,
      thumbSRC: "",
      thumbError: false,
      thumbErrorTxt: "",
      videoFile: null,
      videoSRC: "",
      videoError: false,
      videoErrorTxt: "",
      videoWidth: 0,
      videoHeight: 0,
      videoDuration: "",
      submitBtn: true,
      rules: {
        required: (value) => !!value || "Feild cannot be empty",
        min2: (v) => v.length >= 2 || "Min 2 characters",
        max100: (v) => v.length <= 100 || "Max 100 characters",
        max1000: (v) => v.length <= 1000 || "Max 1000 characters",
        fileRequired: (v) => !!v || "File is required",
        thumbSize: (value) =>
          !value || value.size < 100000 || "File Size should be less than 100 kb!",
        videoSize: (value) =>
          !value || value.size < 400000000 || "File Size should be less than 400 MB!",
      },
      progress: 0,
      progressIntermidiate: true,
      progressText: "",
      success: false,
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      "If you proceed with this action it will result in data corruption and errors. Please wait!"
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getchannels();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    preventNav(event) {
      if (!this.working) return;
      event.preventDefault();
      event.returnValue = "";
    },
    remove(item) {
      this.meta.splice(this.meta.indexOf(item), 1);
      this.meta = [...this.meta];
    },
    checkFeilds() {
      if (this.currentStep == 1) {
        if (this.channel.length == 0 || this.channel == null) {
          this.stepValid[this.currentStep] = true;
        } else {
          this.stepValid[this.currentStep] = false;
        }
      } else if (this.currentStep == 2) {
        if (this.title.length <= 1 || this.title == null) {
          this.stepValid[this.currentStep] = true;
        } else {
          var character = /[`!@#%^*_=[\]{}\\|<>/]/;
          if (character.test(this.title)) {
            this.titleErrorText = "Cannot contain special characters";
            this.stepValid[this.currentStep] = true;
          } else {
            this.titleErrorText = "";
            this.stepValid[this.currentStep] = false;
          }
        }
      } else if (this.currentStep == 4) {
        if (this.medium == "" || this.grade == "" || this.subject == "") {
          this.stepValid[this.currentStep] = true;
        } else {
          this.stepValid[this.currentStep] = false;
        }
      } else if (this.currentStep == 6) {
        if (this.thumbFile != null) {
          let reader = new FileReader();
          reader.readAsDataURL(this.thumbFile);
          reader.onload = (e) => {
            const src = e.target.result;
            const img = new Image();
            img.src = src;

            img.onload = () => {
              if (
                (img.width == 854 && img.height == 480) ||
                (img.width == 864 && img.height == 482)
              ) {
                this.thumbError = false;
                this.thumbErrorTxt = "";
                this.thumbSRC = src;
                this.stepValid[this.currentStep] = false;
              } else {
                this.thumbError = true;
                this.thumbErrorTxt = "Should be 864 x 482 pixels";
                this.thumbSRC = "";
                this.stepValid[this.currentStep] = true;
              }
            };

            img.onerror = () => {
              console.log("error");
            };
          };
        } else {
          this.thumbSRC = "";
          this.stepValid[this.currentStep] = true;
        }
      } else if (this.currentStep == 7) {
        if (this.videoFile != null) {
          this.videoSRC = window.URL.createObjectURL(this.videoFile);
          const video = document.createElement("video");
          video.src = URL.createObjectURL(this.videoFile);

          video.addEventListener("loadedmetadata", (event) => {
            if (
              (video.videoWidth == 854 && video.videoHeight == 480) ||
              (video.videoWidth == 864 && video.videoHeight == 482)
            ) {
              this.videoWidth = video.videoWidth;
              this.videoHeight = video.videoHeight;
              this.videoDuration = this.convertHMS(video.duration);
              this.videoError = false;
              this.videoErrorTxt = "";
              this.submitBtn = false;
            } else {
              this.videoWidth = 0;
              this.videoHeight = 0;
              this.videoDuration = "";
              this.videoError = true;
              this.videoErrorTxt = "Should be 854 x 480 pixels and below 200 MB";
              this.videoSRC = "";
              this.submitBtn = true;
            }
          });
        } else {
          this.videoWidth = 0;
          this.videoHeight = 0;
          this.videoDuration = "";
          this.videoError = true;
          this.videoErrorTxt = "Should be 854 x 480 pixels and below 200 MB";
          this.videoSRC = "";
          this.videoDuration = "";
          this.submitBtn = true;
        }
      }
    },
    getchannels() {
      firebaseFirestore
        .collection("console-users")
        .where("userRole", "==", "console-user")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              name: doc.data().userName,
              avatar: doc.data().avatar,
            };
            this.channels.push(data);
            this.getMediums();
          });
        });
    },
    getMediums() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("mediums")
        .orderBy("medium")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              mediumString: doc.data().medium,
            };
            this.mediums.push(data);
          });
          this.getGrades();
        });
    },
    getGrades() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("grades")
        .orderBy("grade")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              gradeString: doc.data().grade,
            };
            this.grades.push(data);
          });
          this.getSubjects();
        });
    },
    getSubjects() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("subjects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              subjectString: doc.data().subject,
            };
            this.subjects.push(data);
          });
          this.loading = false;
          this.emitLoadingState();
        });
    },
    convertHMS(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours == "00") {
        return minutes + ":" + seconds; // Return is MM : SS
      } else {
        return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
      }
    },
    writeToDB() {
      this.working = true;
      this.progressIntermidiate = true;
      this.progressText = "Creating video details in Database";
      firebaseFirestore
        .collection("console-users")
        .doc(this.channel)
        .collection("videos")
        .add({
          description: this.description,
          duration: this.videoDuration,
          grade: this.grade,
          medium: this.medium,
          meta: this.meta.map((tag) => tag.toLowerCase()),
          subject: this.subject,
          title: this.title,
          views: 0,
          visibility: true,
        })
        .then((returedDoc) => {
          this.uploadThumb(returedDoc.id);
        });
    },
    uploadThumb(videoId) {
      var selfThis = this;

      selfThis.progressIntermidiate = false;
      selfThis.progressText = "Uploading thumbnail";

      const storageRef = firebaseStorage.ref();
      const thumbPath = storageRef.child(
        "content/" + selfThis.channel + "/videos/" + videoId + "/thumb.jpg"
      );
      const metadata = {
        cacheControl: "public,max-age=3600",
        contentType: "image/jpeg",
      };

      var uploadThumbTask = thumbPath.put(selfThis.thumbFile, metadata);

      return new Promise(function (resolve, reject) {
        uploadThumbTask.on(
          "state_changed",
          function (snapshot) {
            selfThis.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            //this.$router.push({ path: "/error", query: { txt: error } });
            console.log(error);
          },
          function () {
            selfThis.uploadVideo(videoId);
          }
        );
      });
    },
    uploadVideo(videoId) {
      var selfThis = this;

      selfThis.progressIntermidiate = false;
      selfThis.progressText = "Uploading video";

      const storageRef = firebaseStorage.ref();
      const videoPath = storageRef.child(
        "content/" + selfThis.channel + "/videos/" + videoId + "/video.mp4"
      );
      const metadata = {
        cacheControl: "no-store",
        contentType: "video/mp4",
      };

      var uploadVideoTask = videoPath.put(selfThis.videoFile, metadata);

      return new Promise(function (resolve, reject) {
        uploadVideoTask.on(
          "state_changed",
          function (snapshot) {
            selfThis.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            //this.$router.push({ path: "/error", query: { txt: error } });
            console.log(error);
          },
          function () {
            selfThis.working = false;
            selfThis.success = true;
          }
        );
      });
    },
  },
};
</script>

<style scoped>
.fieldsStyle {
  font-size: 16pt;
}
.progressPad {
  margin-bottom: 2%;
}
</style>
