<template>
  <v-container>
    <v-row justify="center" class="mt-10">
      <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="9">
        <v-data-table
          :headers="headers"
          :items="reports"
          :page.sync="page"
          :loading="loading"
          item-key="timestamp"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
          ><template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-10" @click="editItem(item)"> mdi-movie-open </v-icon>
            <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
          </template></v-data-table
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px" :persistent="dialogPersistant">
      <v-card v-if="!working">
        <v-card-title class="text-h6"
          >Are you sure you want to delete this report?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteReport">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-if="working">
        <v-card-text class="text-h6">Please wait while we delete the report</v-card-text>
        <v-card-actions>
          <v-progress-linear indeterminate color="accent"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
var moment = require("moment");

export default {
  data: () => ({
    loading: true,
    working: false,
    reports: [],
    page: 1,
    pageCount: 0,
    lastDoc: null,
    noMore: false,
    dialogDelete: false,
    dialogPersistant: false,
    deleteID: "",
    deleteIndex: 0,
    headers: [
      {
        text: "Date time",
        align: "start",
        value: "timestamp",
      },
      { text: "Report Type", value: "reportType" },
      { text: "User", value: "userMobile" },
      { text: "Video", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getReportedList();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getReportedList() {
      firebaseFirestore
        .collection("reports")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              timestamp: this.formatDate(doc.data().timestamp.toDate()),
              reportType: doc.data().reportType,
              userMobile: doc.data().userMobile,
              id: doc.id,
            };
            this.reports.push(data);
            this.loading = false;
            this.emitLoadingState();
          });
        });
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY, h:mm:ss a");
      return mFormatted;
    },
    deleteItem(item) {
      this.deleteIndex = this.reports.indexOf(item);
      this.deleteID = this.reports[this.deleteIndex].id;
      this.dialogDelete = true;
    },
    deleteReport() {
      this.working = true;
      this.dialogPersistant = true;
      firebaseFirestore
        .collection("reports")
        .doc(this.deleteID)
        .delete()
        .then(() => {
          this.reports.splice(this.deleteIndex, 1);
          this.working = false;
          this.dialogPersistant = false;
          this.dialogDelete = false;
        });
    },
  },
};
</script>

<style></style>
