<template>
  <v-container>
      <v-row justify="center" class="mt-10">
      <v-col cols="12" xs="12" sm="12" md="12" lg="11" xl="9">
        <v-data-table
          :headers="headers"
          :items="users"
          :page.sync="page"
          :loading="loading"
          item-key="timestamp"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
          ><template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-10" @click="editItem(item)"> mdi-movie-open </v-icon>
            <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
          </template></v-data-table
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
var moment = require("moment");

export default {
  data: () => ({
    loading: true,
    users: [],
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "Date time",
        align: "start",
        value: "timestamp",
      },
      { text: "Mobile", value: "mobile" },
      { text: "Video", value: "actions", sortable: false },
    ],
  }),
    created() {
      this.loading = true;
      this.emitLoadingState();
      this.getUsersList();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUsersList() {
      firebaseFirestore
        .collection("app-users")
        .orderBy("timestamp").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            mobile: doc.data().mobile,
            timestamp: this.formatDate(doc.data().timestamp.toDate()),
            userID: doc.data().userRole,
          };
          this.users.push(data);
        });
        this.loading = false;
        this.emitLoadingState();
      });
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY, h:mm:ss a");
      return mFormatted;
    },
    goToEdit(userID) {
      window.location.href = "/consoleUsers/" + userID;
    },
    diableUser(){
        this.dialog = true;
    },
  },
}
</script>

<style>

</style>