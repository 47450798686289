<template>
  <v-container>
    <v-row justify="end">
      <v-col cols="12" xs="6" sm="6" md="6" lg="3" xl="3">
        <v-btn
          color="accent"
          class="ma-2 white--text"
          href="/consoleUsers/createUser"
        >
          <v-icon left> mdi-plus </v-icon>
          Create User
        </v-btn>
        <v-btn
          color="accent"
          class="ma-2 white--text"
          @click="createTrending"
        >
          <v-icon left> mdi-plus </v-icon>
          Trending
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
        <v-list subheader two-line v-if="!loading">
          <v-list-item
            v-for="user in users"
            :key="user.id"
            @click="goToEdit(user.id)"
          >
            <v-list-item-avatar>
              <v-img
                :alt="`${user.userName} avatar`"
                :src="user.avatar"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="user.userName"></v-list-item-title>

              <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                v-text="user.userRole"
              ></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    users: [],
  }),
  created() {
      this.loading = true;
      this.emitLoadingState();
      this.getUsersList();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUsersList() {
      var docRef = firebaseFirestore
        .collection("console-users")
        .orderBy("userName");

      if (window.localStorage.userRole == "modarater") {
        docRef = docRef.where("userRole", "==", "console-user");
      }
      docRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            avatar: doc.data().avatar,
            email: doc.data().email,
            id: doc.data().id,
            userName: doc.data().userName,
            userRole: doc.data().userRole,
            status: doc.data().status,
          };
          this.users.push(data);
        });
        this.loading = false;
        this.emitLoadingState();
      });
    },
    goToEdit(userID) {
      window.location.href = "/consoleUsers/" + userID;
    },
    diableUser(){
        this.dialog = true;
    },
    createTrending(){
      var callFunction = firebaseFunctions.httpsCallable("generateTrending");

      callFunction.call();
    }
  },
};
</script>

<style>
</style>