<template>
  <div>
    <v-card>
      <v-card-title>{{ headline }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newValue"
          :label="label"
          :counter="counter"
          :rules="rules"
          :hint="hint"
          :errorMessages="errorMessages"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="working" @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="createBtn" color="accent" text :loading="working" @click="addValue"> Create </v-btn>
        <v-btn v-if="!createBtn" color="accent" text :loading="working" @click="editValue"> Update </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore } from "@/main";
import { EventBus } from "@/main";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    itemID: {
      type: String,
      required: true,
    },
    popUpdialog: {
      type: Boolean,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    newValue: "",
    newItemID: "",
    working: false,
    errorMessages: "",
    headline: "",
    label: "",
    counter: 0,
    hint: "",
    createBtn: true,
  }),
  computed: {
    rules() {
      return [
        (value) => !!value || "Required.",
        (value) =>
          (value || "").length <= this.counter || "Max " + this.counter + " characters",
      ];
    },
  },
  created() {
    this.setData();
  },
  watch: {
    popUpdialog(visible) {
      if (visible) {
        this.setData();
      }
    },
    value: function (val) {
      this.newValue = val;
      this.setData();
    },
    itemID: function (val) {
      this.newItemID = val;
      this.setData();
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:popUpdialog", false);
    },
    setData() {
      switch (this.action) {
        case "Add Grade":
          this.headline = "Add new grade";
          this.label = "Grade";
          this.counter = 10;
          this.hint = "Type new grade name";
          this.createBtn = true;
          break;
        case "Add Subject":
          this.headline = "Add new subject";
          this.label = "Subject";
          this.counter = 25;
          this.hint = "Type new subject name";
          this.createBtn = true;
          break;
        case "Edit Grade":
          this.headline = "Edit grade";
          this.label = "Grade";
          this.counter = 10;
          this.hint = "Type to update grade name";
          this.newValue = this.value;
          this.createBtn = false;
          break;
        case "Edit Subject":
          this.headline = "Edit subject";
          this.label = "Subject";
          this.counter = 25;
          this.hint = "Type to update subject name";
          this.newValue = this.value;
          this.createBtn = false;
          break;
      }
    },
    addValue() {
      switch (this.label) {
        case "Grade":
          if (this.values.some((x) => x.value === this.newValue)) {
            this.errorMessages = "Grade already exists";
          } else {
            this.working = true;
            var newDocRef1 = firebaseFirestore
              .collection("controls")
              .doc("video")
              .collection("grades")
              .doc();

            newDocRef1
              .set({
                grade: this.newValue,
                id: newDocRef1.id,
              })
              .then(() => {
                this.$router.go({ path: this.$router.path });
              });
          }
          break;
        case "Subject":
          if (this.values.some((x) => x.value === this.newValue)) {
            this.errorMessages = "Subject already exists";
          } else {
            this.working = true;
            var newDocRef2 = firebaseFirestore
              .collection("controls")
              .doc("video")
              .collection("subjects")
              .doc();

            newDocRef2
              .set({
                subject: this.newValue,
                id: newDocRef2.id,
              })
              .then(() => {
                this.$router.go({ path: this.$router.path });
              });
          }
          break;
      }
    },
    editValue() {
      if (this.newValue == this.value) {
        this.closeDialog();
      } else {
        switch (this.label) {
          case "Grade":
            if (this.values.some((x) => x.value === this.newValue)) {
              this.errorMessages = "Grade already exists";
            } else {
              this.working = true;
              firebaseFirestore
                .collection("controls")
                .doc("video")
                .collection("grades")
                .doc(this.itemID)
                .set(
                  {
                    grade: this.newValue,
                  },
                  { merge: true }
                )
                .then(() => {
                  this.$router.go({ path: this.$router.path });
                });
            }
            break;
          case "Subject":
            if (this.values.some((x) => x.value === this.newValue)) {
              this.errorMessages = "Subject already exists";
            } else {
              this.working = true;
              firebaseFirestore
                .collection("controls")
                .doc("video")
                .collection("subjects")
                .doc(this.itemID)
                .set(
                  {
                    subject: this.newValue,
                  },
                  { merge: true }
                )
                .then(() => {
                  this.$router.go({ path: this.$router.path });
                });
            }
            break;
        }
      }
    },
  },
};
</script>

<style></style>
