<template>
  <v-container fluid class="pa-4 ma-0">
    <v-layout row wrap>
      <v-flex v-for="channel in channels" :key="channel.id" xs12 sm12 md6 lg4 xl3>
        <v-hover v-slot="{ hover }" open-delay="10" class="cardPad">
          <v-card
            tile
            :class="{ 'on-hover': hover }"
            :elevation="hover ? 4 : 2"
            @click="goToChannel(channel.id, channel.userName)"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ channel.totalSubscribers }} Subscribers -
                  {{ channel.totalVideos }} Views
                </div>
                <v-list-item-title class="text-h6 mb-1"
                  >{{ channel.userName }}
                </v-list-item-title>
                <v-list-item-subtitle class="d-inline-block text-truncate">{{
                  channel.email
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar tile size="80">
                <img :src="channel.avatar" />
              </v-list-item-avatar>
            </v-list-item>
            <a class="ml-4 secondary--text" v-if="channel.ViewsLabel.length > 1">Subscribers</a>
            <v-sparkline
              :labels="channel.SubLabels"
              :value="channel.SubValues"
              color="secondary"
              line-width="2"
              padding="16"
              label-size="10"
              smooth
              fill
            ></v-sparkline>
            <a class="ml-4 accent--text" v-if="channel.ViewsLabel.length > 1">Views</a>
            <v-sparkline
              :labels="channel.ViewsLabel"
              :value="channel.ViewsValue"
              color="accent"
              line-width="2"
              padding="16"
              label-size="10"
              smooth
              fill
            ></v-sparkline>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore } from "@/main";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    channels: [],
    count: 0,
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getUsersList();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUsersList() {
      firebaseFirestore
        .collection("console-users")
        .orderBy("userName")
        .where("userRole", "==", "console-user")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            await this.getSubHistory(doc);
            this.count++;
          });
        });
    },
    async getSubHistory(doc) {
      let SubLabels = [],
        SubValues = [],
        ViewsLabel = [],
        ViewsValue = [];

      firebaseFirestore
        .collection("console-users")
        .doc(doc.data().id)
        .collection("subscriptionHistory")
        .orderBy("timestamp")
        .limit(8)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            SubLabels.push(doc.data().month);
            SubValues.push(doc.data().subscribers);
          });
        })
        .then((_) => {
          firebaseFirestore
            .collection("console-users")
            .doc(doc.data().id)
            .collection("viewHistory")
            .orderBy("timestamp")
            .limit(7)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                ViewsLabel.push(doc.data().month + "-" + doc.data().date);
                ViewsValue.push(doc.data().totalViews);
              });
            })
            .then((_) => {
              const data = {
                avatar: doc.data().avatar,
                cover: doc.data().cover,
                email: doc.data().email,
                id: doc.data().id,
                started: doc.data().started,
                status: doc.data().status,
                totalSubscribers: doc.data().totalSubscribers,
                totalVideos: doc.data().totalVideos,
                totalViews: doc.data().totalViews,
                userName: doc.data().userName,
                userRole: doc.data().userRole,
                SubLabels: SubLabels,
                SubValues: SubValues,
                ViewsLabel: ViewsLabel,
                ViewsValue: ViewsValue,
              };
              this.channels.push(data);
              if (this.count == this.channels.length) {
                this.loading = false;
                this.emitLoadingState();
              }
            });
        });
    },
    goToChannel(channelID, channelName) {
      window.location.href = "/channels/" + channelID + "/" + channelName;
    },
  },
};
</script>

<style lang="sass" scoped>
.cardPad
  margin-top: 2%,
  margin-left: 1%,
  margin-right: 1%,
  margin-bottom: 1%,
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.2)
  >.v-card__text
    color: #000
</style>
