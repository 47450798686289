<template>
  <v-container class="full" fill-height fluid>
    <v-row justify="center"
      ><v-col cols="12" xs="12" sm="11" md="7" lg="6" xl="6">
        <v-card class="rounded-lg pa-2" :elevation="7">
          <v-row class="totalPadding" align="center" justify="center">
            <v-col cols="12" xs="12" sm="11" md="4" lg="5" xl="5">
              <v-img src="@/assets/Edutube_Logo.svg" />
            </v-col>
            <v-col cols="12" xs="12" sm="11" md="8" lg="7" xl="7">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  class="textFeildFont"
                  v-model="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  type="email"
                  color="primary"
                  :rules="rulesEmail"
                />
                <v-text-field
                  class="textFeildFont"
                  v-model="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  color="primary"
                  :rules="rulesPass"
                />
                <v-btn
                  class="loginBtnPad"
                  v-on:click="validate"
                  :loading="loginFunRuning"
                  color="primary"
                  large
                  block
                  >Login</v-btn
                >
              </v-form>
            </v-col>
          </v-row></v-card
        ><v-alert v-model="alert" type="error" dismissible class="errorPad">{{
          errorTxt
        }}</v-alert></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { firebaseAuth, firebaseFirestore } from "@/main";

export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      alert: false,
      errorTxt: "Error",
      loginFunRuning: false,
      valid: true,
      rulesEmail: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 40 || "Max 40 characters",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      rulesPass: [
        (value) => !!value || "Required.",
        (value) => (value || "").length >= 6 || "Min 6 characters",
      ],
      userName: "",
      userEmail: "",
      userRole: "",
      userID: "",
      userAvatar: "",
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate() == true) {
        this.login();
      } else {
        this.$refs.form.validate();
      }
    },
    login: function (e) {
      this.loginFunRuning = true;
      firebaseAuth.signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          firebaseFirestore
            .collection("console-users")
            .doc(user.user.uid)
            .get()
            .then((returnedDoc) => {
              console.log(returnedDoc);
              localStorage.userName = returnedDoc.data().userName;
              localStorage.userEmail = returnedDoc.data().email;
              localStorage.userID = user.user.uid;
              localStorage.userRole = returnedDoc.data().userRole;
              localStorage.userAvatar = returnedDoc.data().avatar;
            })
            .then(() => {
              this.$router.go({ path: this.$router.path });
            });
        },
        (err) => {
          this.alert = true;
          this.errorTxt = err.message.substring(9);
          this.loginFunRuning = false;
        }
      );
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
.full {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(0deg, #00239c 0%, #041d74 100%);
}
.totalPadding {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.textFeildFont {
  font-size: 1.2em;
}
.loginBtnPad {
  margin-top: 5%;
}
.errorPad {
  margin-top: 10%;
}
</style>