<template>
  <v-container> </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
var moment = require("moment");

export default {
  data: () => ({
    loading: true,
    working: false,
    channelName: "",
    videoTitle: "",
    uploaded: "",
    views: 0,
    visits: 0,
    description: "",
    grade: "",
    subject: "",
    medium: "",
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getReportedList();
  },
  methods: {
    getVideoDetails() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .get()
        .then((doc) => {
          this.channelName = doc.data().userName;
        })
        .then(() => {
          firebaseFirestore
            .collection("console-users")
            .doc(this.$route.params.channelID)
            .collection("videos")
            .doc(this.$route.params.videoID)
            .get()
            .then((doc) => {
              this.videoTitle = doc.data().title;
              this.uploaded = this.formatDate(doc.data().timestamp.toDate());
              this.views = doc.data().views;
              this.description = doc.data().description;
            });
        });
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY, h:mm:ss a");
      return mFormatted;
    },
  },
};
</script>

<style></style>
