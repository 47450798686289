<template>
  <v-container fluid>
    <v-tabs align-with-title color="accent" class="pl-1">
      <v-tab>Stats</v-tab>
      <v-tab>Videos</v-tab>
      <v-tab>About</v-tab>
      <v-tab-item>
        <v-row class="mx-1" justify="center">
          <v-col cols="12" xs="12" sm="11" md="6" lg="6" xl="6">
            <v-card class="ma-5 text-center" color="accent" dark max-width="100%">
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :labels="ViewsLabel"
                    :value="ViewsValue"
                    color="rgba(255, 255, 255, .7)"
                    height="100"
                    padding="24"
                    stroke-linecap="round"
                    line-width="1"
                    smooth
                  >
                    <template v-slot:label="item"> {{ item.value }} </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h4 font-weight-thin">Views Last 30 days</div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn block text> Go to Report </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="11" md="6" lg="6" xl="6">
            <v-card class="ma-5 text-center" color="accent" dark max-width="100%">
              <v-card-text>
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :labels="SubLabels"
                    :value="SubValues"
                    color="rgba(255, 255, 255, .7)"
                    height="100"
                    padding="24"
                    stroke-linecap="round"
                    line-width="1"
                    smooth
                  >
                    <template v-slot:label="item"> {{ item.value }} </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>

              <v-card-text>
                <div class="text-h4 font-weight-thin">Subscribers Last 6 months</div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn block text> Go to Report </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-layout row wrap
            ><v-flex v-for="video in videos" :key="video.id" xs12 sm12 md5 lg4 xl3>
              <v-hover v-slot="{ hover }" open-delay="10" class="pa-2">
                <v-card
                  tile
                  :color="`${hover ? 'nav' : 'base'}`"
                  elevation="0"
                  @click="goToVideo(video.id, video.title)"
                >
                  <v-img :src="video.thumbURL" />
                  <div class="mt-2">
                    <div class="d-inline-block text-truncate">
                      {{ video.title }}
                    </div>
                    <div>{{ video.views }} Views • {{ video.duration }}</div>
                    <div class="primary--text">
                      {{ video.grade }} • {{ video.subject }} • {{ video.medium }}
                    </div>
                    <p></p>
                  </div>
                </v-card>
              </v-hover> </v-flex
          ></v-layout>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card class="pa-10">
              <v-card-text>
                <v-textarea
                  v-model="aboutUser"
                  filled
                  label="About Channel"
                  auto-grow
                  :disabled="!enableEditAbout"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="!enableEditAbout"
                  text
                  color="accent"
                  @click="enableEditAbout = true"
                >
                  Edit About details
                </v-btn>
                <v-btn
                  v-if="enableEditAbout"
                  color="accent"
                  @click="updateAbout"
                  :loading="working"
                >
                  Update changes
                </v-btn>
                <p class="red--text">{{ errorText }}</p>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";

export default {
  data: () => ({
    loading: true,
    working: false,
    cover: "",
    aboutUser: "",
    enableEditAbout: false,
    errorText: "",
    ViewsLabel: [],
    ViewsValue: [],
    SubLabels: [],
    SubValues: [],
    videos: [],
    mediums: [],
    grades: [],
    subjects: [],
  }),
  created() {
    this.$route.meta.title = this.$route.params.channelName;
    this.loading = true;
    this.emitLoadingState();
    this.getChannelDetails();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      "If you proceed with this action it will result in data corruption and errors. Please wait!"
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    preventNav(event) {
      if (!this.working) return;
      event.preventDefault();
      event.returnValue = "";
    },
    getChannelDetails() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .get()
        .then((doc) => {
          this.cover = doc.data().cover;
          this.aboutUser = doc.data().aboutUser;
          this.getStats();
        });
    },
    getStats() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .collection("viewHistory")
        .orderBy("timestamp")
        .limit(30)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.ViewsLabel.push(doc.data().date);
            this.ViewsValue.push(doc.data().totalViews);
          });
        })
        .then(() => {
          firebaseFirestore
            .collection("console-users")
            .doc(this.$route.params.channelID)
            .collection("subscriptionHistory")
            .orderBy("timestamp")
            .limit(30)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.SubLabels.push(doc.data().month);
                this.SubValues.push(doc.data().subscribers);
              });
            })
            .then(() => {
              this.getMediums();
            });
        });
    },
    getMediums() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("mediums")
        .orderBy("medium")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              mediumString: doc.data().medium,
            };
            this.mediums.push(data);
          });
        })
        .then((_) => {
          this.getGrades();
        });
    },
    getGrades() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("grades")
        .orderBy("grade")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              gradeString: doc.data().grade,
            };
            this.grades.push(data);
          });
        })
        .then((_) => {
          this.getSubjects();
        });
    },
    getSubjects() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("subjects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              subjectString: doc.data().subject,
            };
            this.subjects.push(data);
          });
        })
        .then((_) => {
          this.getVideos();
        });
    },
    getVideos() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .collection("videos")
        .orderBy("timestamp")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              duration: doc.data().duration,
              title: doc.data().title,
              thumbURL: doc.data().thumbURL,
              id: doc.data().id,
              views: doc.data().views,
              medium: this.getValueText(doc.data().medium, "Medium"),
              grade: this.getValueText(doc.data().grade, "Grade"),
              subject: this.getValueText(doc.data().subject, "Subject"),
            };
            this.videos.push(data);
          });
        })
        .then((_) => {
          this.loading = false;
          this.emitLoadingState();
        });
    },
    getValueText(id, type) {
      let obj;
      switch (type) {
        case "Medium":
          obj = this.mediums.find((obj) => obj.id === id);
          return obj.mediumString;
        case "Grade":
          obj = this.grades.find((obj) => obj.id === id);
          return obj.gradeString;
        case "Subject":
          obj = this.subjects.find((obj) => obj.id === id);
          return obj.subjectString;
      }
    },
    goToVideo(videoID, videoTitle) {
      window.location.href =
        "/video/" + this.$route.params.channelID + "/" + videoID + "/" + videoTitle;
    },
    updateAbout() {
      this.working = true;
      var updateAboutFunc = firebaseFunctions.httpsCallable("updateAbout");

      updateAboutFunc({
        aboutUser: this.aboutUser,
        channelID: this.$route.params.channelID,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.enableEditAbout = false;
        } else {
          this.working = false;
          this.enableEditAbout = false;
          this.errorText = result.data.error;
          console.log("Error " + result.data.error);
        }
      });
    },
  },
};
</script>

<style scoped>
.statsCardPad {
  margin-top: 5%;
  margin-bottom: 5%;
}
.thumbPad {
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
}
.thumbTxtPad {
  margin-top: 2%;
  margin-left: 3%;
  margin-right: 10%;
}
</style>
