<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation class="topPad">
      <v-row justify="center">
        <v-col cols="12" xs="12" sm="11" md="3" lg="5" xl="5">
          <v-text-field
            v-model="userName"
            :rules="[rules.required, rules.min, rules.max]"
            :disabled="working || loading"
            label="Channel Name / User Name"
            required
            type="text"
            counter="30"
            color="secondary"
          />
        </v-col>

        <v-col cols="12" xs="12" sm="11" md="4" lg="5" xl="5">
          <v-select
            v-model="role"
            :items="roles"
            :rules="[rules.requiredRole]"
            :disabled="working || loading"
            label="User Role"
            required
            color="secondary"
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="11" md="7" lg="7" xl="7">
          <v-textarea
            v-model="about"
            label="About user"
            color="secondary"
            :disabled="working || loading"
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="11" md="6" lg="4" xl="4">
          <v-row justify="center">
            <v-file-input
              v-model="avatarFile"
              label="Pick an avatar"
              hint="180 x 180 pixels & below 50 kb"
              :persistent-hint="true"
              accept=".jpg"
              prepend-icon="mdi-image-plus"
              :error-messages="avatarErrorTxt"
              :error="avatarError"
              @change="analizeAvatar"
              :rules="[rules.avatarSize]"
              :disabled="working || loading"
              color="secondary"
              filled
              rounded
            />
          </v-row>

          <v-row justify="center">
            <v-avatar size="180">
              <img :src="avatarSRC" />
            </v-avatar>
          </v-row>
        </v-col>

        <v-col cols="12" xs="1" sm="1" md="1" lg="1" xl="1"></v-col>

        <v-col cols="12" xs="12" sm="11" md="6" lg="4" xl="4">
          <v-row justify="center">
            <v-file-input
              v-model="coverFile"
              label="Pick a cover"
              hint="600 x 240 pixels & below 100 kb"
              :persistent-hint="true"
              accept=".jpg"
              prepend-icon="mdi-image-area"
              :error-messages="coverErrorTxt"
              :error="coverError"
              @change="analizeCover"
              :rules="[rules.coverSize]"
              :disabled="working || loading"
              color="secondary"
              filled
              rounded
            />
          </v-row>

          <v-row justify="center">
            <v-img max-height="180" max-width="450" :src="coverSRC"></v-img>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-switch
          class="createBtnPad"
          v-model="status"
          inset
          label="User status"
          color="green"
        ></v-switch>
      </v-row>

      <v-row justify="center" v-if="progressVisible">
        <v-col cols="12" xs="12" sm="11" md="6" lg="5" xl="5">
          <v-progress-linear
            class="createBtnPad"
            color="accent"
            height="25"
            :value="progressValue"
            rounded
            striped
            ><strong>{{ progressText }}</strong></v-progress-linear
          >
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="11" sm="10" md="4" lg="3" xl="3">
          <v-btn
            v-if="!progressVisible"
            class="createBtnPad"
            :disabled="!valid"
            :loading="working || loading"
            color="accent"
            @click="validate"
            block
            large
          >
            Update User
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title :class="dialogtitleClass">
            {{ dialogtitle }}
          </v-card-title>
          <v-card-text class="text-body-1">{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="okayAction">
              Okay
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";

export default {
  data: () => ({
    working: true,
    loading: true,
    valid: true,
    userName: "",
    role: "",
    about: "",
    status: false,
    rules: {
      required: (value) => !!value || "Feild cannot be empty",
      min: (v) => v.length >= 6 || "Min 6 characters",
      max: (v) => v.length <= 25 || "Max 25 characters",
      requiredRole: (v) => !!v || "Role has to be assigned",
      fileRequired: (v) => !!v || "Image is required",
      avatarSize: (value) =>
        !value || value.size < 50000 || "File Size should be less than 50 kb!",
      coverSize: (value) =>
        !value ||
        value.size < 100000 ||
        "File Size should be less than 100 kb!",
    },
    roles: [],
    avatarFile: null,
    avatarError: false,
    avatarErrorTxt: "",
    avatarSRC: "",
    coverFile: null,
    coverError: false,
    coverErrorTxt: "",
    coverSRC: "",
    progressVisible: false,
    progressText: "",
    progressValue: 0,
    dialog: false,
    dialogtitle: "",
    dialogtitleClass: "",
    dialogText: "",
  }),
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      "If you proceed with this action it will result in data corruption and errors. Please wait!"
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    this.userAccessControl();
    this.getUserDetails();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    okayAction() {
      window.location.href = "/consoleUsers";
    },
    preventNav(event) {
      if (!this.loading) return;
      event.preventDefault();
      event.returnValue = "";
    },
    userAccessControl() {
      if (window.localStorage.userRole == "manager") {
        this.roles = ["manager", "modarater", "console-user"];
      } else if (window.localStorage.userRole == "modarater") {
        this.roles = ["modarater", "console-user"];
      } else {
        this.roles = [];
      }
      this.loading = false;
      this.emitLoadingState();
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.working = true;
        this.loading = true;
        this.emitLoadingState();
        this.updateUser();
      } else {
        this.$refs.form.validate();
      }
    },
    analizeAvatar() {
      this.avatarChanged = true;
      if (this.avatarFile != null) {
        let reader = new FileReader();
        reader.readAsDataURL(this.avatarFile);
        reader.onload = (e) => {
          const src = e.target.result;
          const img = new Image();
          img.src = src;

          img.onload = () => {
            if (img.width == 180 && img.height == 180) {
              this.avatarError = false;
              this.avatarErrorTxt = "";
              this.avatarSRC = src;
            } else {
              this.avatarError = true;
              this.avatarErrorTxt = "Should be 180 x 180 pixels";
              this.avatarSRC = "";
            }
          };

          img.onerror = () => {
            console.log("error");
          };
        };
      } else {
        this.avatarSRC = "";
      }
    },
    analizeCover() {
      this.coverChanged = true;
      if (this.coverFile != null) {
        let reader = new FileReader();
        reader.readAsDataURL(this.coverFile);
        reader.onload = (e) => {
          const src = e.target.result;
          const img = new Image();
          img.src = src;

          img.onload = () => {
            if (img.width == 600 && img.height == 240) {
              this.coverError = false;
              this.coverErrorTxt = "";
              this.coverSRC = src;
            } else {
              this.coverError = true;
              this.coverErrorTxt = "Should be 600 x 240 pixels";
              this.coverSRC = "";
            }
          };

          img.onerror = () => {
            console.log("error");
          };
        };
      } else {
        this.coverSRC = "";
      }
    },
    getUserDetails() {
      this.working = true;
      this.loading = true;
      this.emitLoadingState();
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.userID)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.exists) {
            this.userName = querySnapshot.data().userName;
            this.role = querySnapshot.data().userRole;
            this.about = querySnapshot.data().aboutUser;
            this.status = querySnapshot.data().status;
            this.avatarSRC =
              "https://storage.googleapis.com/candela-edutube.appspot.com/content/" +
              this.$route.params.userID +
              "/user/avatar.jpg";
            this.coverSRC =
              "https://storage.googleapis.com/candela-edutube.appspot.com/content/" +
              this.$route.params.userID +
              "/user/cover.jpg";
          } else {
            console.log("Missing user doc");
          }
        })
        .then(() => {
          this.working = false;
          this.loading = false;
          this.emitLoadingState();
        });
    },
    updateUser() {
      var self = this;
      var updateUser = firebaseFunctions.httpsCallable("updateConsoleUser");
      this.working = true;

      updateUser({
        userID: this.$route.params.userID,
        userName: this.userName,
        userRole: this.role,
        aboutUser: this.about,
        status: this.status,
      }).then((result) => {
        if (result.data.response == "Success") {
          if (self.avatarFile != null) {
            self.progressVisible = true;
            self.uploadAvatar(
              "content/" + self.$route.params.userID + "/user/"
            );
          } else if (self.coverFile != null) {
            self.progressVisible = true;
            self.uploadCover("content/" + self.$route.params.userID + "/user/");
          } else {
            self.showSuccess();
          }
        } else if (result.data.response == "Error") {
          self.progressVisible = false;
          self.working = false;
          self.loading = false;
          self.emitLoadingState();
          self.dialogtitle = "Error!";
          self.dialogtitleClass = "text-h5 red--text";
          self.dialogText = "Error in updating user - " + result.data.userID;
          self.dialog = true;
        } else if (result.data.response == "Access denied") {
          self.progressVisible = false;
          self.working = false;
          self.loading = false;
          self.emitLoadingState();
          self.dialogtitle = "Access Denied!";
          self.dialogtitleClass = "text-h5 red--text";
          self.dialogText = "You do not have permission to complete this task!";
          self.dialog = true;
        } else {
          self.progressVisible = false;
          self.working = false;
          self.loading = false;
          self.emitLoadingState();
          self.dialogtitle = "Error!";
          self.dialogtitleClass = "text-h5 red--text";
          self.dialogText = "Error in updating user. Check server log";
          self.dialog = true;
        }
      });
    },
    uploadAvatar(userIDPath) {
      var self = this;
      const storageRef = firebaseStorage.ref();
      const uploadPathAvatar = storageRef.child(userIDPath + "avatar.jpg");

      this.progressText = "Uploading Avatar";
      var uploadingTaskAvatar = uploadPathAvatar.put(this.avatarFile);

      return new Promise(function (resolve, reject) {
        uploadingTaskAvatar.on(
          "state_changed",
          function (snapshot) {
            self.progressValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            self.progressVisible = false;
            self.working = false;
            self.loading = false;
            self.emitLoadingState();
            self.dialogtitle = "Error!";
            self.dialogtitleClass = "text-h5 red--text";
            self.dialogText = "Error in creating user - " + error;
            self.dialog = true;
          },
          function () {
            if (self.coverFile != null) {
              self.uploadCover(userIDPath);
            } else {
              self.showSuccess();
            }
          }
        );
      });
    },
    uploadCover(userIDPath) {
      var self = this;
      const storageRef = firebaseStorage.ref();
      const uploadPathCover = storageRef.child(userIDPath + "cover.jpg");

      this.progressText = "Uploading Cover";
      var uploadingTaskCover = uploadPathCover.put(this.coverFile);

      return new Promise(function (resolve, reject) {
        uploadingTaskCover.on(
          "state_changed",
          function (snapshot) {
            self.progressValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            console.log(error);
            self.progressVisible = false;
            self.working = false;
            self.loading = false;
            self.emitLoadingState();
            self.dialogtitle = "Error!";
            self.dialogtitleClass = "text-h5 red--text";
            self.dialogText = "Error in creating user - " + error;
            self.dialog = true;
          },
          function () {
            self.showSuccess();
          }
        );
      });
    },
    showSuccess() {
      this.progressVisible = false;
      this.working = false;
      this.loading = false;
      this.emitLoadingState();
      this.dialogtitle = "Success!";
      this.dialogtitleClass = "text-h5 green--text";
      this.dialogText = "User and Channel account updated Successfully.";
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.createBtnPad {
  margin-top: 2%;
}
.topPad {
  margin-top: 1%;
}
</style>