import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import VueCoreVideoPlayer from 'vue-core-video-player'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueCoreVideoPlayer);
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import firebase from 'firebase/compat/app';
require("firebase/compat/auth");
require("firebase/compat/firestore");
require("firebase/compat/functions");
require("firebase/compat/storage");
require("firebase/compat/messaging");
require("firebase/compat/database");


const firebaseConfig = {
  apiKey: "AIzaSyBskbZmZm0sLyxo2BbSwWoyFgyJVHZFuOU",
  authDomain: "candela-edutube.firebaseapp.com",
  databaseURL: "https://candela-edutube.firebaseio.com",
  projectId: "candela-edutube",
  storageBucket: "candela-edutube.appspot.com",
  messagingSenderId: "739654813710",
  appId: "1:739654813710:web:4163c7602eb4f26cf5945f",
  measurementId: "G-6JFH333Q5T"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebaseFirestore = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseFunctions = firebase.functions();
export const firebaseStorage = firebase.storage();
export const messaging = firebase.messaging();

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})

export const EventBus = new Vue();

Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    console.log(user)
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
});