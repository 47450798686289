<template>
  <v-container>
    <v-row justify="space-around" fluid no-gutters>
      <v-col cols="12" xs="10" sm="10" md="6" lg="2" xl="2">
        <userCountCard
          class="my-5"
          image="appIcon.svg"
          :title="this.totalUsers"
          sub-title="Total Users"
          color="background-color:#304FFE;"
        />
      </v-col>
      <v-col cols="12" xs="10" sm="10" md="6" lg="2" xl="2">
        <userCountCard
          class="my-5"
          image="dialogIcon.svg"
          :title="this.dialogUsers"
          sub-title="Total Users"
          color="background-color:#771D6F;"
        />
      </v-col>
      <v-col cols="12" xs="10" sm="10" md="6" lg="2" xl="2">
        <userCountCard
          class="my-5"
          image="airtelIcon.svg"
          :title="this.airtelUsers"
          sub-title="Total Users"
          color="background-color:#FF0000;"
        />
      </v-col>
      <v-col cols="12" xs="10" sm="10" md="6" lg="2" xl="2">
        <userCountCard
          class="my-5"
          image="hutchIcon.svg"
          :title="this.hutchUsers"
          sub-title="Total Users"
          color="background-color:#FF6600;"
        />
      </v-col>
      <v-col cols="12" xs="10" sm="10" md="6" lg="2" xl="2">
        <userCountCard
          class="my-5"
          image="payhereIcon.svg"
          :title="this.payhereUsers"
          sub-title="Total Users"
          color="background-color:#cc820a;"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div id="chart">
          <div id="chart-timeline">
            <apexchart
              type="area"
              height="350"
              ref="chart"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import userCountCard from "./component/userCountCard.vue";
import VueApexCharts from "vue-apexcharts";

var moment = require("moment");

export default {
  components: {
    userCountCard,
    apexchart: VueApexCharts,
  },
  data: () => ({
    loading: true,
    totalUsers: "",
    dialogUsers: "",
    airtelUsers: "",
    hutchUsers: "",
    payhereUsers: "",
    series: [],
    chartOptions: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: new Date("01 Mar 2012").getTime(),
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    selection: "one_year",
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getStats();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getStats() {
      firebaseFirestore
        .collection("analytics")
        .doc("userCount")
        .get()
        .then((doc) => {
          this.totalUsers = doc.data().totalUsers.toString();
          this.dialogUsers = doc.data().dialogUsers.toString();
          this.airtelUsers = doc.data().airtelUsers.toString();
          this.hutchUsers = doc.data().hutchUsers.toString();
          this.payhereUsers = doc.data().payhereUsers.toString();
          this.series = [
            {
              data: [
                [1327359600000, 1],
                [1327446000000, 3],
                [1327532400000, 4],
                [1327618800000, 7],
                [1327878000000, 10],
                [1327964400000, 14],
                [1328050800000, 16],
                [1328137200000, 29],
                [1328223600000, 35],
                [1328482800000, 46],
                [1328569200000, 58],
                [1328655600000, 61],
                [1328742000000, 65],
                [1328828400000, 71],
                [1329087600000, 75],
                [1329174000000, 84],
                [1329260400000, 86],
                [1329346800000, 96],
                [1329433200000, 105],
                [1329778800000, 114],
                [1329865200000, 123],
                [1329951600000, 127],
                [1330038000000, 131],
                [1330297200000, 133],
                [1330383600000, 143],
                [1330470000000, 153],
                [1330556400000, 161],
              ],
            },
          ];
        });
      this.loading = false;
      this.emitLoadingState();
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY, h:mm:ss a");
      return mFormatted;
    },
    goToEdit(userID) {
      window.location.href = "/consoleUsers/" + userID;
    },
    diableUser() {
      this.dialog = true;
    },
  },
};
</script>

<style></style>
