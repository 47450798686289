<template>
  <v-container>
    <v-alert text type="success" v-if="success">
      <strong>Notification sent successfully!</strong>
    </v-alert>
    <v-row justify="center" class="mt-10 mx-2">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            class="fieldsStyle"
            v-model="title"
            :rules="[rules.required, rules.min4, rules.max100]"
            counter="100"
            label="Title"
            filled
            rounded
          ></v-text-field>
          <v-textarea
            v-model="body"
            counter
            label="Body Text"
            :rules="[rules.required, rules.max500]"
            :value="body"
            rows="5"
            filled
            rounded
          ></v-textarea>
          <v-file-input
            v-model="imageFile"
            label="Notification Image"
            hint="1024 x 512 pixels & below 100 kb"
            :persistent-hint="true"
            accept=".jpg"
            prepend-icon="mdi-image-area"
            :error="imageError"
            :error-messages="imageErrorTxt"
            @change="loadImage"
            :rules="[rules.fileRequired, rules.imageSize]"
            color="secondary"
            :show-size="1000"
            filled
            rounded
          />
          <v-btn
            class="mt-8"
            color="accent"
            block
            large
            @click="validate"
            :loading="working"
          >
            Send Notification to all devices
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="5">
        <v-row justify="center" class="mt-5">
          <v-img
            v-if="imageFile != null"
            max-height="1024"
            max-width="512"
            :src="imageSRC"
          ></v-img>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseStorage, firebaseFunctions } from "@/main";

export default {
  data() {
    return {
      valid: false,
      working: false,
      success: false,
      title: "",
      body: "",
      imageFile: null,
      imageSRC: "",
      imageError: false,
      imageErrorTxt: "",
      rules: {
        required: (value) => !!value || "Feild cannot be empty",
        min4: (v) => v.length >= 4 || "Min 4 characters",
        max100: (v) => v.length <= 100 || "Max 100 characters",
        max500: (v) => v.length <= 500 || "Max 500 characters",
        fileRequired: (v) => !!v || "File is required",
        imageSize: (value) =>
          !value || value.size < 100000 || "File Size should be less than 100 kb!",
      },
    };
  },
  methods: {
    loadImage() {
      if (this.imageFile != null) {
        let reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onload = (e) => {
          const src = e.target.result;
          const img = new Image();
          img.src = src;

          img.onload = () => {
            if (img.width == 1024 && img.height == 512) {
              this.imageSRC = src;
              this.imageError = false;
              this.imageErrorTxt = "";
            } else {
              this.imageSRC = "";
              this.imageError = true;
              this.imageErrorTxt = "Should be 1024 x 512 pixels";
            }
          };

          img.onerror = () => {
            console.log("error");
          };
        };
      } else {
        this.imageSRC = "";
        this.imageError = false;
        this.imageErrorTxt = "";
      }
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.sendNotification();
      } else {
        this.$refs.form.validate();
      }
    },
    sendNotification() {
      this.working = true;
      var imageID = Date.now().toString();
      var generalNotification = firebaseFunctions.httpsCallable("generalNotification");
      const storageRef = firebaseStorage.ref();
      const thumbPath = storageRef.child("inAppMessaging/" + imageID + "/image.jpg");
      const metadata = {
        cacheControl: "public,max-age=3600",
        contentType: "image/jpeg",
      };

      thumbPath.put(this.imageFile, metadata).then(() => {
        generalNotification({
          title: this.title,
          body: this.body,
          imageID: imageID,
        }).then((result) => {
            this.working = false;
            this.success = true;
            console.log("Error " + result.data.response);
        });
      });
    },
  },
};
</script>

<style></style>
