<template>
  <v-container>
    <v-row justify="end" class="mt-5">
      <v-btn tile color="primary" class="mr-5" @click="showDialog('grade')">
        <v-icon left color="white"> mdi-plus </v-icon>
        Add Grade
      </v-btn>
      <v-btn tile color="primary" class="mr-5" @click="showDialog('subject')">
        <v-icon left color="white"> mdi-plus </v-icon>
        Add Subjects
      </v-btn>
      <v-btn tile color="accent" class="mr-15" href="/controls/Lessonslist">
        <v-icon left color="white"> mdi-book-open-variant </v-icon>
        Lessons List
      </v-btn>
    </v-row>
    <v-row justify="center" class="my-5">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="4">
        <v-list subheader two-line v-if="grades.length >= 1">
          <!-- Mediums -->
          <v-subheader inset>Mediums</v-subheader>
          <v-list-item v-for="medium in mediums" :key="medium.id">
            <v-list-item-avatar>
              <v-icon class="green" dark> mdi-information </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="medium.value"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Grades -->
          <v-divider inset></v-divider>
          <v-subheader inset>Grades</v-subheader>
          <v-list-item v-for="grade in grades" :key="grade.id">
            <v-list-item-avatar>
              <v-icon class="red" dark> mdi-folder-multiple-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="grade.value"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="editDialog('grade', grade.value, grade.id)">
                <v-icon color="grey lighten-1">mdi-database-edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="5">
        <!-- Subjects -->
        <v-list subheader two-line v-if="subjects.length >= 1">
          <v-subheader inset>Subjects</v-subheader>
          <v-list-item v-for="subject in subjects" :key="subject.id">
            <v-list-item-avatar>
              <v-icon class="deep-purple darken-4" dark>mdi-layers</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="subject.value"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="editDialog('subject', subject.value, subject.id)">
                <v-icon color="grey lighten-1">mdi-database-edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="popUpdialog" max-width="500" persistent>
      <addEditDialog
        :action="action"
        :popUpdialog.sync="popUpdialog"
        :values.sync="values"
        :value="value"
        :itemID="itemID"
      />
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import addEditDialog from "./component/addEditDialog";

export default {
  components: {
    addEditDialog,
  },
  data: () => ({
    loading: true,
    mediums: [],
    grades: [],
    subjects: [],
    values: [],
    popUpdialog: false,
    action: "",
    value: "",
    itemID: "",
  }),

  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getMediums();
  },

  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getMediums() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("mediums")
        .orderBy("medium")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().medium,
            };
            this.mediums.push(data);
          });
          this.getGrades();
        });
    },
    getGrades() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("grades")
        .orderBy("grade")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().grade,
            };
            this.grades.push(data);
            this.values.push(data);
          });
          this.getSubjects();
        });
    },
    getSubjects() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("subjects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              value: doc.data().subject,
            };
            this.subjects.push(data);
            this.values.push(data);
          });
          this.loading = false;
          this.emitLoadingState();
        });
    },
    showDialog(type) {
      if (type == "grade") {
        this.action = "Add Grade";
      } else if (type == "subject") {
        this.action = "Add Subject";
      }

      this.value = "";
      this.itemID = "";
      this.popUpdialog = true;
    },
    editDialog(type, string, id) {
      if (type == "grade") {
        this.action = "Edit Grade";
      } else if (type == "subject") {
        this.action = "Edit Subject";
      }
      this.value = string;
      this.itemID = id;
      this.popUpdialog = true;
    },
  },
};
</script>

<style></style>
