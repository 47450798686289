<template>
  <v-container v-if="!loading">
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card>
          <v-card-text>
            <apexchart
              height="300"
              type="area"
              :options="optionsViews"
              :series="seriesViews"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card>
          <v-card-text>
            <apexchart
              height="300"
              type="area"
              :options="optionsVisit"
              :series="seriesVisit"
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-5">
      <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5">
        <div>
          <vue-core-video-player
            v-if="!loading"
            :src="currVideoSRC"
            :cover="currThumbSRC"
          ></vue-core-video-player>
        </div>
        <v-row justify="center" class="pt-10">
          <v-btn class="mr-5" color="primary" @click="thumbDialog = true">
            Change Thumbnail
          </v-btn>
          <v-btn color="primary" @click="videoDialog = true"> Change Video </v-btn>
        </v-row>
        <v-row justify="center" class="pt-15 mx-16">
          <v-btn
            color="error"
            :loading="working"
            @click="confirmationDialog2 = true"
            block
            x-large
          >
            Delete Video
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="7" xl="7">
        <v-form ref="form" v-model="valid" lazy-validation
          ><v-text-field
            class="fieldsStyle"
            v-model="title"
            :rules="[rules.required, rules.min4, rules.max100]"
            counter="100"
            label="Title"
            hint="Title"
            :disabled="working"
            filled
            rounded
            @change="changed = true"
          ></v-text-field
          ><v-textarea
            v-model="description"
            counter
            label="Description"
            :rules="[rules.max1000]"
            :value="description"
            :disabled="working"
            rows="7"
            filled
            rounded
            @change="changed = true"
          ></v-textarea
          ><v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-overflow-btn
                v-model="medium"
                :items="mediums"
                label="Medium"
                item-value="id"
                item-text="mediumString"
                :disabled="working"
                @change="changed = true"
              ></v-overflow-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-overflow-btn
                v-model="grade"
                :items="grades"
                label="Grade"
                item-value="id"
                item-text="gradeString"
                :disabled="working"
                @change="changed = true"
              ></v-overflow-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-overflow-btn
                v-model="subject"
                :items="subjects"
                label="Subject"
                hint="Subject"
                item-value="id"
                item-text="subjectString"
                :disabled="working"
                @change="changed = true"
              ></v-overflow-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row justify="center">
                <v-switch
                  v-model="visibility"
                  inset
                  label="Video visible to user"
                  color="green"
                ></v-switch> </v-row
            ></v-col> </v-row
          ><v-combobox
            v-model="meta"
            :items="items"
            :disabled="working"
            label="Type keywords and press enter"
            chips
            clearable
            multiple
            solo
            rounded
            filled
            @change="changed = true"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>
              </v-chip>
            </template>
          </v-combobox>
          <v-btn
            color="accent"
            block
            :loading="working"
            :disabled="!changed"
            @click="validate"
          >
            Update Video Details
          </v-btn></v-form
        >
      </v-col>
    </v-row>
    <!------------------------------------------------------------ Confirmation Dialogs -->
    <v-row justify="center">
      <v-dialog v-model="confirmationDialog1" max-width="408">
        <div class="text-center">
          <v-sheet
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="nav"
            dark
          >
            <div class="grey--text text--lighten-1 text-body-1 mb-4">
              Are you sure you want to update video details?
            </div>

            <v-btn class="ma-1" color="grey" plain @click="confirmationDialog1 = false">
              Cancel
            </v-btn>
            <v-btn class="ma-1" color="error" plain @click="updateVideoDetails">
              Yes
            </v-btn>
          </v-sheet>
        </div>
      </v-dialog>
      <!----------------------------------------------------------------- Thumb Dialogs -->
      <v-dialog v-model="thumbDialog" max-width="500" persistent>
        <v-card class="pa-5" color="white">
          <v-img
            v-if="thumbFile != null"
            max-height="480"
            max-width="852"
            :src="thumbSRC"
            class="mb-5"
          ></v-img>
          <v-file-input
            light
            v-model="thumbFile"
            label="Pick a thumbnail"
            hint="852 x 480 pixels & below 100 kb"
            :persistent-hint="true"
            accept=".jpg"
            prepend-icon="mdi-image-area"
            :error-messages="thumbErrorTxt"
            :error="thumbError"
            :rules="[rules.fileRequired, rules.thumbSize]"
            :show-size="1000"
            :disabled="working"
            @change="setThumbFile"
            filled
            rounded
          />
          <v-btn
            v-if="!working && !success"
            class="mt-4"
            color="accent"
            block
            rounded
            large
            light
            @click="uploadThumbnail"
            :disabled="!uploadBtn"
          >
            Upload Thumbnail
          </v-btn>
          <v-alert class="mt-4" v-if="success" text type="success">
            <strong>Thumbnail uploaded successfully!</strong>
          </v-alert>
          <v-progress-linear
            class="mt-4"
            v-if="working"
            v-model="progress"
            height="35"
            stream
            rounded
            striped
            color="accent"
            ><strong>{{ Math.ceil(progress) }}%</strong></v-progress-linear
          ><v-btn block light plain @click="closeDialog" v-if="!working"> Close </v-btn>
        </v-card>
      </v-dialog>
      <!----------------------------------------------------------------- Video Dialogs -->
      <v-dialog v-model="videoDialog" max-width="500" persistent>
        <v-card class="pa-5" color="white">
          <div class="player-container">
            <vue-core-video-player
              v-if="videoFile != null"
              :src="videoSRC"
            ></vue-core-video-player>
          </div>
          <v-file-input
            class="mt-5"
            v-model="videoFile"
            label="Upload Video"
            hint="852 x 480 pixels & below 200 MB"
            :persistent-hint="true"
            accept=".mp4"
            prepend-icon="mdi-movie"
            :error-messages="videoErrorTxt"
            :error="videoError"
            @change="setVideoFile"
            :rules="[rules.fileRequired, rules.videoSize]"
            :disabled="working"
            :show-size="1000"
            filled
            rounded
            light
          />
          <v-btn
            v-if="!working && !success"
            class="mt-4"
            color="accent"
            block
            rounded
            large
            light
            @click="uploadVideo"
            :disabled="!uploadBtn"
          >
            Upload Video
          </v-btn>
          <v-alert class="mt-4" v-if="success" text type="success">
            <strong>Video uploaded successfully!</strong>
          </v-alert>
          <v-progress-linear
            class="mt-4"
            v-if="working"
            v-model="progress"
            height="35"
            stream
            rounded
            striped
            color="accent"
            ><strong>{{ Math.ceil(progress) }}%</strong></v-progress-linear
          ><v-btn block light plain @click="closeDialog" v-if="!working"> Close </v-btn>
        </v-card>
      </v-dialog>
      <!------------------------------------------------------------ Delete Dialogs -->
      <v-dialog v-model="confirmationDialog2" max-width="408">
        <div class="text-center">
          <v-sheet
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="nav"
            dark
          >
            <div class="grey--text text--lighten-1 text-body-1 mb-4">
              Are you sure you want to delete this video? This action cannot be undone!
            </div>

            <v-btn class="ma-1" color="grey" plain @click="confirmationDialog2 = false">
              Cancel
            </v-btn>
            <v-btn class="ma-1" color="error" plain @click="deleteVideo"> Yes </v-btn>
          </v-sheet>
        </div>
      </v-dialog>
      <!------------------------------------------------------------ Delete Confirmation -->
      <v-dialog v-model="confirmationDialog3" max-width="300" persistent>
        <div class="text-center">
          <v-sheet
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="nav"
            dark
          >
            <div class="grey--text text--lighten-1 text-h5 mb-4">
              Video Deleted successfully!
            </div>

            <v-btn class="ma-1 text-h4" color="accent" plain @click="goBackPage">
              Okay
            </v-btn>
          </v-sheet>
        </div>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import VueApexCharts from "vue-apexcharts";

export default {
  data: () => ({
    loading: true,
    working: false,
    valid: false,
    title: "",
    description: "",
    mediums: [],
    grades: [],
    subjects: [],
    medium: "",
    grade: "",
    subject: "",
    meta: [],
    items: [],
    currVideoSRC: "",
    currThumbSRC: "",
    visibility: false,
    rules: {
      required: (value) => !!value || "Feild cannot be empty",
      min4: (v) => v.length >= 4 || "Min 4 characters",
      max100: (v) => v.length <= 100 || "Max 100 characters",
      max1000: (v) => v.length <= 1000 || "Max 1000 characters",
      fileRequired: (v) => !!v || "File is required",
      thumbSize: (value) =>
        !value || value.size < 300000 || "File Size should be less than 100 kb!",
      videoSize: (value) =>
        !value || value.size < 200000000 || "File Size should be less than 200 MB!",
    },
    changed: false,
    confirmationDialog1: false,
    confirmationDialog2: false,
    confirmationDialog3: false,
    uploadBtn: false,
    progress: 0,
    success: false,
    thumbDialog: false,
    thumbSRC: "",
    thumbFile: null,
    thumbError: false,
    thumbErrorTxt: "",
    videoDialog: false,
    videoSRC: "",
    videoFile: null,
    videoError: false,
    videoErrorTxt: "",
    videoWidth: 0,
    videoHeight: 0,
    videoDuration: "",
    optionsViews: {
      chart: {
        id: "vuechart-example",
        foreColor: "#FF4560",
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      stroke: {
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        colors: ["#FF4560"],
        strokeColor: "#FF4560",
        strokeWidth: 2,
      },
      colors: ["#FF4560"],
      tooltip: {
        theme: "",
      },
      title: {
        text: "Views",
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "",
        },
      },
    },
    optionsVisit: {
      chart: {
        id: "vuechart-example",
        foreColor: "#66DA26",
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      stroke: {
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        colors: ["#66DA26"],
        strokeColor: "#66DA26",
        strokeWidth: 2,
      },
      colors: ["#66DA26"],
      tooltip: {
        theme: "",
      },
      title: {
        text: "Visits",
        align: "left",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "",
        },
      },
    },
    seriesViews: [
      {
        name: "Views",
        data: [],
      },
    ],
    seriesVisit: [
      {
        name: "Visits",
        data: [],
      },
    ],
  }),
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      "If you proceed with this action it will result in data corruption and errors. Please wait!"
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    this.$route.meta.title = this.$route.params.videoTitle;
    if (localStorage.theme == "dark") {
      this.optionsViews.tooltip.theme = "dark";
      this.optionsVisit.tooltip.theme = "dark";
      this.optionsViews.title.style.color = "#607D8B";
      this.optionsVisit.title.style.color = "#607D8B";
    } else {
      this.optionsViews.tooltip.theme = "light";
      this.optionsVisit.tooltip.theme = "light";
      this.optionsViews.title.style.color = "#3F51B5";
      this.optionsVisit.title.style.color = "#3F51B5";
    }
    this.loading = true;
    this.emitLoadingState();
    this.getStats();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    preventNav(event) {
      if (!this.working) return;
      event.preventDefault();
      event.returnValue = "";
    },
    remove(item) {
      this.meta.splice(this.meta.indexOf(item), 1);
      this.meta = [...this.meta];
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.confirmationDialog1 = true;
      } else {
        this.$refs.form.validate();
      }
    },
    goBackPage() {
      this.$router.go(-1);
    },
    closeDialog() {
      this.uploadBtn = false;
      this.progress = 0;
      this.success = false;
      this.thumbDialog = false;
      this.thumbSRC = "";
      this.thumbFile = null;
      this.thumbError = false;
      this.thumbErrorTxt = "";
      this.videoDialog = false;
      this.videoSRC = "";
      this.videoFile = null;
      this.videoError = false;
      this.videoErrorTxt = "";
    },
    getStats() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .collection("videos")
        .doc(this.$route.params.videoID)
        .collection("viewHistory")
        .orderBy("timestamp")
        .limit(365)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.optionsViews.xaxis.categories.push(
              doc.data().timestamp.toDate().toISOString()
            );
            this.seriesViews[0].data.push(doc.data().totalViews);
          });
        })
        .then(() => {
          firebaseFirestore
            .collection("console-users")
            .doc(this.$route.params.channelID)
            .collection("videos")
            .doc(this.$route.params.videoID)
            .collection("visitHistory")
            .orderBy("timestamp")
            .limit(365)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.optionsVisit.xaxis.categories.push(
                  doc.data().timestamp.toDate().toISOString()
                );
                this.seriesVisit[0].data.push(doc.data().totalVisits);
              });
            })
            .then(() => {
              this.getMediums();
            });
        });
    },
    getMediums() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("mediums")
        .orderBy("medium")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              mediumString: doc.data().medium,
            };
            this.mediums.push(data);
          });
          this.getGrades();
        });
    },
    getGrades() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("grades")
        .orderBy("grade")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              gradeString: doc.data().grade,
            };
            this.grades.push(data);
          });
          this.getSubjects();
        });
    },
    getSubjects() {
      firebaseFirestore
        .collection("controls")
        .doc("video")
        .collection("subjects")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              id: doc.data().id,
              subjectString: doc.data().subject,
            };
            this.subjects.push(data);
          });
          this.getVideoDetails();
        });
    },
    getVideoDetails() {
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .collection("videos")
        .doc(this.$route.params.videoID)
        .get()
        .then((doc) => {
          this.currVideoSRC = doc.data().videoURL;
          this.currThumbSRC = doc.data().thumbURL;
          this.title = doc.data().title;
          this.description = doc.data().description;
          this.medium = doc.data().medium;
          this.grade = doc.data().grade;
          this.subject = doc.data().subject;
          this.meta = doc.data().meta;
          this.visibility = doc.data().visibility;
          this.loading = false;
          this.emitLoadingState();
        });
    },
    setThumbFile() {
      if (this.thumbFile != null) {
        let reader = new FileReader();
        reader.readAsDataURL(this.thumbFile);
        reader.onload = (e) => {
          const src = e.target.result;
          const img = new Image();
          img.src = src;

          img.onload = () => {
            if (
              (img.width == 854 && img.height == 480) ||
              (img.width == 864 && img.height == 482)
            ) {
              this.thumbError = false;
              this.thumbErrorTxt = "";
              this.thumbSRC = src;
              this.uploadBtn = true;
            } else {
              this.thumbError = true;
              this.thumbErrorTxt = "Should be 854 x 480 pixels";
              this.thumbSRC = "";
              this.uploadBtn = false;
            }
          };

          img.onerror = () => {
            console.log("error");
          };
        };
      } else {
        this.thumbSRC = "";
        this.uploadBtn = false;
      }
    },
    convertHMS(value) {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours == "00") {
        return minutes + ":" + seconds; // Return is MM : SS
      } else {
        return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
      }
    },
    setVideoFile() {
      if (this.videoFile != null) {
        this.videoSRC = window.URL.createObjectURL(this.videoFile);
        const video = document.createElement("video");
        video.src = URL.createObjectURL(this.videoFile);

        video.addEventListener("loadedmetadata", (event) => {
          if (video.videoWidth == 854 && video.videoHeight == 480) {
            this.videoWidth = video.videoWidth;
            this.videoHeight = video.videoHeight;
            this.videoDuration = this.convertHMS(video.duration);
            this.videoError = false;
            this.videoErrorTxt = "";
            this.uploadBtn = true;
          } else {
            this.videoWidth = 0;
            this.videoHeight = 0;
            this.videoDuration = "";
            this.videoError = true;
            this.videoErrorTxt = "Should be 854 x 480 pixels and below 200 MB";
            this.videoSRC = "";
            this.uploadBtn = false;
          }
        });
      } else {
        this.videoWidth = 0;
        this.videoHeight = 0;
        this.videoDuration = "";
        this.videoError = true;
        this.videoErrorTxt = "Should be 854 x 480 pixels and below 200 MB";
        this.videoSRC = "";
        this.videoDuration = "";
        this.uploadBtn = false;
      }
    },
    updateVideoDetails() {
      this.confirmationDialog1 = false;
      this.working = true;
      firebaseFirestore
        .collection("console-users")
        .doc(this.$route.params.channelID)
        .collection("videos")
        .doc(this.$route.params.videoID)
        .set(
          {
            title: this.title,
            description: this.description,
            grade: this.grade,
            medium: this.medium,
            subject: this.subject,
            meta: this.meta.map((tag) => tag.toLowerCase()),
            visibility: this.visibility,
          },
          { merge: true }
        )
        .then(() => {
          this.working = false;
          this.changed = false;
        });
    },
    uploadThumbnail() {
      this.working = true;
      var selfThis = this;
      const storageRef = firebaseStorage.ref();
      const thumbPath = storageRef.child(
        "content/" +
          this.$route.params.channelID +
          "/videos/" +
          this.$route.params.videoID +
          "/thumb.jpg"
      );
      const metadata = {
        cacheControl: "public,max-age=3600",
        contentType: "image/jpeg",
      };

      var uploadThumbTask = thumbPath.put(this.thumbFile, metadata);

      return new Promise(function (resolve, reject) {
        uploadThumbTask.on(
          "state_changed",
          function (snapshot) {
            selfThis.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            //this.$router.push({ path: "/error", query: { txt: error } });
            console.log(error);
          },
          function () {
            selfThis.success = true;
            selfThis.working = false;
            selfThis.currThumbSRC = selfThis.thumbSRC;
            selfThis.progress = 0;
          }
        );
      });
    },
    uploadVideo() {
      this.working = true;
      var selfThis = this;

      const storageRef = firebaseStorage.ref();
      const videoPath = storageRef.child(
        "content/" +
          this.$route.params.channelID +
          "/videos/" +
          this.$route.params.videoID +
          "/video.mp4"
      );
      const metadata = {
        cacheControl: "no-store",
        contentType: "video/mp4",
      };

      var uploadVideoTask = videoPath.put(this.videoFile, metadata);

      return new Promise(function (resolve, reject) {
        uploadVideoTask.on(
          "state_changed",
          function (snapshot) {
            selfThis.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100 - 1;
          },
          function (error) {
            //this.$router.push({ path: "/error", query: { txt: error } });
            console.log(error);
          },
          function () {
            firebaseFirestore
              .collection("console-users")
              .doc(selfThis.$route.params.channelID)
              .collection("videos")
              .doc(selfThis.$route.params.videoID)
              .set(
                {
                  duration: selfThis.videoDuration,
                },
                { merge: true }
              )
              .then(() => {
                selfThis.success = true;
                selfThis.working = false;
                selfThis.currVideoSRC = selfThis.videoSRC;
                selfThis.progress = 0;
              });
          }
        );
      });
    },
    deleteVideo() {
      this.working = true;
      this.confirmationDialog2 = false;

      var deleteVideoFunc = firebaseFunctions.httpsCallable("videoDeleted");

      deleteVideoFunc({
        channelID: this.$route.params.channelID,
        videoID: this.$route.params.videoID,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.working = false;
          this.confirmationDialog3 = true;
        } else {
          this.working = false;
          console.log("Error " + JSON.stringify(result.data.error));
        }
      });
    },
  },
};
</script>

<style scoped>
.fieldsStyle {
  font-size: 16pt;
}
</style>
